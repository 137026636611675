$berry-base: #7E79A9;
$berry-light1: #8F8AB4;
$berry-light2: #9E9BBF;
$berry-light3: #B8B5CF;

$green: #2BB673;
$green-light: #9DDA50;
$yellow: #FFDA1B;
$orange: #FFAA47;
$red: #E94600;

$grey-dark: #727272;
$grey-base: #EEEEEE;
$grey-light: #f1f1f1;
$black: #000000;
$white: #FFFFFF;

:export {
  greyLight: $grey-light;
  greyDark: $grey-dark;
  
  purple: $berry-base;
  purpleLight1:$berry-light1;
  purpleLight2:$berry-light2;
  purpleLight3:$berry-light3;
 
  black: $black;
  white: $white;

  green:$green;
  greenLight:$green-light;
  yellow:$yellow;
  orange:$orange;
  red:$red;
}


.carbonEstimateCard {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.01em;
    z-index: 0;
    -webkit-font-smoothing: antialiased;
}

.annotationText {
  color:$grey-dark;
  font-size:16px;
  margin-top:-10px;
  height:auto;
  font-weight:400;
}

.iconText{
  display:flex;
  gap:8px;
  align-items:center;
}

.heading {
  margin:0; 
  font-weight:600;
}

.infoButtonIcon{
  color: $berry-base;
}