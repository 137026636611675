.container {
  display: grid;
  grid-template-columns: auto min-content;
  :global(.ant-typography-edit-content) {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
.addButton {
  align-self: start;
}