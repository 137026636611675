.modal {
  min-width: 1450px;
  :global(.ant-modal-body) {
    overflow-x: auto; // adds horizontal scroll for too wild content (table in our case)
  }
}

.controls {
  display: grid;
  grid-template-columns: auto min-content;
  grid-column-gap: 20px;
  margin-bottom: 16px;
}

.table {
  :global(.ant-table-tbody) {
    td {
      font-size: 14px;
    }
  }

  tr{
    &:global(.ant-table-row-level-1){
      background-color: rgba(169, 169, 169, 0.1);
    }

    &:global(.ant-table-row-level-2){
      background-color: rgba(169, 169, 169, 0.175);
    }

    &:global(.ant-table-row-level-3){
      background-color: rgba(169, 169, 169, 0.25);
    }
  }

  span{
    &:global(.ant-typography-danger){
      text-decoration: underline;
    }

    &:global(.unsure-jaccard){
      font-size: 110%;
      font-weight: 500;
    }
  }
}