body {
    background-color: white;
}

button {
    * {
        font-size: 14px;
    }
}

.content {
    width: 90%;
    margin: auto;
    position: relative;
}

//TODO adjust the theme instead...

$greyBackground: #f8f8f8;
$foodfactsGreen: #2ab673;

.ant-layout {
    background: white !important;
}

.sidebar-menu {
    background-color: white !important;
    margin-top: 10vh !important;
    * {
        font-size: 20px;
        font-weight: 300;
    }
}

.ant-table {
    td {
        font-size: 20px;
        font-weight: 300;
        cursor: pointer;
    }
}

.ant-layout-sider-light {
    background-color: white !important;
}
.ant-layout-sider-light .ant-layout-sider-trigger {
    background-color: $greyBackground !important;
}

.ant-layout-footer {
    background-color: $greyBackground !important;
}

.header .ant-typography h2,
h2.ant-typography {
    margin: 0 !important;
}

.main-wrapper {
    background-color: white;
}

.header-wrapper {
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0 2px 8px #f0f1f2;

    z-index: 100;
}

.header-avatar {
    background-color: rgba(0, 0, 0, 0.25) !important;
}

.mark-item {
    border-radius: 3px;
    animation: highlight 1000ms ease-out;
}

@keyframes highlight {
    0% {
        background-color: $foodfactsGreen;
    }
    100% {
        background-color: inherit;
    }
}

.disableOnSmallScreen {
    display: block;
}

.enableOnSmallScreen {
    display: none;
}

@media (max-width: 768px) {
    .disableOnSmallScreen {
        display: none;
    }
    .enableOnSmallScreen {
        display: block;
    }
}

.hoverable:hover {
    background-color: yellow;
}

@font-face {
    font-family: 'Inter';
    src: url(fonts/Inter-Regular.woff2);
  }
