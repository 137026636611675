.suggestEditButton {
  font-size: 1em;
  display: block;
  position: absolute;
  right: 0;
  padding-top: 30px;
  box-sizing: content-box;
  & :global(.anticon) {
    font-size: 1em;
    & > * {
      font-size: 32px;
      line-height: 1.23;
    }
  }
}
