.whole {
    height: 100%;
    display: flex;
    flex-direction: column;

    .top-section {
    }
    .middle-section {
        flex: auto;
    }
    .footer-section {
    }
}
