.add-button {
    float: right;
}

.delete-button {
    :hover {
        padding: 2px;
        background-color: rgb(245, 92, 92);
        fill: white;
        border-radius: 100%;
    }
}
